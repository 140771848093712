import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, forkJoin, take } from 'rxjs';
import { Request } from 'src/app/models/dbModels/request';
import { School } from 'src/app/models/dbModels/school';
import { StateReq } from 'src/app/models/dbModels/stateReq';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { TypeRequest } from 'src/app/models/dbModels/typeRequest';
import { User } from 'src/app/models/dbModels/user';
import { UserCourse } from 'src/app/models/dbModels/userCourse';
import { ShowRequestFloatingBox } from 'src/app/models/requestWatcher/showRequestFloatingBox';
import { AuthService } from 'src/app/services/auth.service';
import { DirectivasService } from 'src/app/services/directivas.service';
import { RequestService } from 'src/app/services/request.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';
import { SchoolService } from 'src/app/services/school.service';
import { StateReqService } from 'src/app/services/stateReq.service';
import { SubTypesRequestService } from 'src/app/services/subTypeRequest.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { UserCourseService } from 'src/app/services/userCourse.service';

@Component({
  selector: 'request-request-view-admin',
  templateUrl: './request-view-admin.component.html',
  styleUrls: ['./request-view-admin.component.css']
})
export class RequestViewAdminComponent implements OnInit, OnDestroy {

  public panelRightIndex: number;
  public requestSelected: Request;
  public ALL_MY_REQUEST: Request[] = [];
  public ALL_MY_REQUEST_DEFAULT: Request[] = [];
  public ALL_REQUEST_TO_TAKE: Request[] = [];
  public ALL_STATES_REQUEST: StateReq[] = [];
  public REQUESTER_USER: User; // TODO: Review this... name and functionality ok?
  public ALL_SCHOOLS_LIST: School[] = [];
  public ALL_PARENT_AND_CHILDRENS_SCHOOLS_IDS: number[] = [];
  public idSchoolFromRoleList: number = 0;
  private indexRequestSelected: number;
  public tabSelected: number = 1;
  public idSchoolRequestViewer: number;

  constructor(
    private _stateReqService: StateReqService,
    private _requestService: RequestService,
    private _schoolService: SchoolService,
    private _authService: AuthService,
    private _directivasService: DirectivasService,
    private _userCourseService: UserCourseService,
    private _router: Router,
    private _sweetalertService: SweetalertService,
    private _subTypesRequestService: SubTypesRequestService,
    private _requestWatcherUserService: RequestWatcherUserService,
  ) {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this.panelRightIndex = 0;
    const UserID = this._authService.getCurrentUserID();
    if (!UserID || !Number.isInteger(parseInt(UserID))) this._router.navigate(['/user/my-account']);
    this.validateUserRoles();
  }
  
  ngOnInit(): void {
    this.subscriptionShowFloatingBox = this._requestWatcherUserService.adminStudentShowFloatingBox.subscribe((data: ShowRequestFloatingBox) => {
      this.showFloatingBox = data.showBox;
      if (data.isMassive) this.panelRightIndex = 4;
    });
    this.subscriptionLoadRequestToShow = this._requestWatcherUserService.loadRequestToShowObservable.subscribe((data: number) => {
      this.typeFilterToGetRequest = data;
      if (this.typeFilterToGetRequest == 2) {
        this.ALL_MY_REQUEST = this.ALL_REQUEST_TO_TAKE;
        this.orderAllRequestByLastUpdate(this.ALL_REQUEST_TO_TAKE);
      } else this.getAllInitialData();
    });
    this.subscriptionLoadSectionAdminToView = this._requestWatcherUserService.SECTION_ADMIN_TO_VIEW_OBSERVABLE.subscribe((data: number) => {
      this.tabSelected = data;
    });
  }

  validateUserRoles(): void {
    forkJoin([
      this._requestService.getMyRoleMappings().pipe(take(1))
    ]).subscribe({
      next: (data:any) => {
        let allRolesWithAccess = [
          'admin'.toUpperCase(),
          'Financiera'.toUpperCase(),
          'Solicitudes'.toUpperCase(),
          'Registro y Control'.toUpperCase()
        ];
        let redirectToprofile: boolean = true;
        data[0].forEach(role => {
          if ( allRolesWithAccess.includes(role.role.name.toUpperCase()) ) {
            redirectToprofile = false;
            this.idSchoolFromRoleList = role.SchoolID;
          } else { /** Validar esto para celic */
            redirectToprofile = false;
            this.idSchoolFromRoleList = role.SchoolID;
          }
        });
        if (redirectToprofile) this._router.navigate(['/user/my-account']);
      },
      error: (err) => {
        console.log("ERROR validateUserRoles",err)
      },
      complete: () => {
        // console.log('complete validateUserRoles');
      }
      
    })
  }

  getAllInitialData() {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      ALL_STATES_REQUEST: this._stateReqService.getStatesReq().pipe(take(1)),
      ALL_MY_REQUEST: this._requestService.getRequest(this.getRequestFilter()).pipe(take(1)),
      REQUESTER_USER: this._requestService.getCurrentUser(),
      ALL_SUBTYPE_REQUEST: this._requestService.getSubTypeRequest().pipe(take(1)),
      ALL_TYPE_REQUEST: this._requestService.getTypeRequest().pipe(take(1)),
      ALL_SCHOOLS_LIST: this._schoolService.getSchools().pipe(take(1)),
      ALL_REQUEST_TO_TAKE: this._requestService.getRequestToTake(Number(this._authService.getCurrentUserID()), JSON.parse(this.getRequestFilter())).pipe(take(1)),
      ALL_MY_REQUEST_V2: this._requestService.getAllMyRequest(Number(this._authService.getCurrentUserID()), JSON.parse(this.getRequestFilter())).pipe(take(1)),
    }).subscribe({
      next: (data:any) => {
        console.log('getAllInitialData',data);
        this.ALL_STATES_REQUEST = data.ALL_STATES_REQUEST;
        this.ALL_REQUEST_TO_TAKE = data.ALL_REQUEST_TO_TAKE;
        if (this.typeFilterToGetRequest == 0) { // Las del usuario
          this.ALL_MY_REQUEST = data.ALL_MY_REQUEST_V2;
          this.ALL_MY_REQUEST_DEFAULT = data.ALL_MY_REQUEST_V2;
        }
        if (this.typeFilterToGetRequest == 1) { // Las de la escuela
          this.ALL_MY_REQUEST = data.ALL_MY_REQUEST;
          this.ALL_MY_REQUEST_DEFAULT = data.ALL_MY_REQUEST;
        }
        this.orderAllRequestByLastUpdate(this.ALL_MY_REQUEST);
        this.REQUESTER_USER = data.REQUESTER_USER;
        this.ALL_SUBTYPE_REQUEST = data.ALL_SUBTYPE_REQUEST;
        this.ALL_TYPE_REQUEST = data.ALL_TYPE_REQUEST;
        this.ALL_SCHOOLS_LIST = data.ALL_SCHOOLS_LIST;
        let schoolParentAndChilds = this.ALL_SCHOOLS_LIST.filter(schoolIterated => (schoolIterated.id == this.idSchoolFromRoleList || schoolIterated.DepenSchoolID == this.idSchoolFromRoleList));
        this.ALL_PARENT_AND_CHILDRENS_SCHOOLS_IDS = [];
        schoolParentAndChilds.forEach(schoolParentAndChildsIterated => {
          this.ALL_PARENT_AND_CHILDRENS_SCHOOLS_IDS.push(schoolParentAndChildsIterated.id!);
        });
        this._requestWatcherUserService.updateAllSchoolsObservable(this.ALL_SCHOOLS_LIST);
        this._requestWatcherUserService.updateAllSubTypeRequestObservable(this.ALL_SUBTYPE_REQUEST);
        this._requestWatcherUserService.updateAllTypeRequestObservable(this.ALL_TYPE_REQUEST);
        this._sweetalertService.swalClose();
        if (this.ALL_MY_REQUEST.length == 0) this._sweetalertService.swalError('','No hay solicitudes para mostrar.', () => {});
      },
      error: (err) => {
        this._sweetalertService.swalError('Error','No se pudo cargar la información, inténtelo nuevamente, si el error persiste, contacte a soporte.', () => {});
        console.log("ERROR getAllInitialData viewAdmin",err)
      },
      complete: () => {
        console.log('complete getAllInitialData RVA');
      }
    })
  }

  orderAllRequestByLastUpdate(allRequest: Request[]): void {
    allRequest.forEach(element => {
      let lastIndex = element.requestHistories!.length;
      if (lastIndex > 0) {
        element.lastActionUpdateDate = element.requestHistories![lastIndex-1].updateDate
      } else {
        element.lastActionUpdateDate = element.updateDate
      }
    });
    allRequest.sort(function(a,b) {
      return new Date(b.lastActionUpdateDate!).getTime() - new Date(a.lastActionUpdateDate!).getTime();
    });
    this.ALL_MY_REQUEST = allRequest;
  }


  updateRequestList() {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin([
      this._requestService.getRequest(this.getRequestFilter()).pipe(take(1)),
    ]).subscribe({
      next: (data) => {
        this._sweetalertService.swalClose();
        console.log('updateRequestList',data);
        this.ALL_MY_REQUEST = data[0];
        this.orderAllRequestByLastUpdate(data[0]);
        if (this.indexRequestSelected >= 0) this.requestSelected = this.ALL_MY_REQUEST[this.indexRequestSelected];
      },
      error: (err) => {
        console.log("ERROR updateRequestList",err)
        this._sweetalertService.swalError('Error','No se pudo actualizar la información, si el error persiste, contacte a soporte.', () => {})
      }
    })
  }

  showDataRequestSelected(data: {index: number, request: Request}): void {
    this.requestSelected = data.request;
    this.panelRightIndex = data.index;
    let index = this.ALL_MY_REQUEST.findIndex( element => element.id == data.request.id );
    if (index >= 0) this.indexRequestSelected = index;
  }

  closeChatView(): void {
    this.panelRightIndex = 0;
  }

  onSearchUser(email) {
    console.log(email);
    this._directivasService.getUsersByEmail(email).subscribe({
      next: userData => {
        console.log("SUCCES onSearchUser",userData)
        if (userData.length > 0) {
          this.userFound = true;
          this.dataUserFound = userData[0];
        }
      },
      error: err => {
        console.log("ERROR onSearchUser",err)
      }
    })
  }

  public userFound: boolean = false;
  public dataUserFound: User;
  public ALL_TYPE_REQUEST: TypeRequest[] = [];
  public ALL_SUBTYPE_REQUEST: SubTypeRequest[] = [];
  public ALL_USER_COURSES: UserCourse[] = [];
  getAllDataForUser() {
    console.log('getAllDataForUser');
    const filterUserCourse = JSON.stringify({
      where: {
        UserID: this.dataUserFound.id
      },
      include: [ {'coursesOfer':"school"} ]
    });
    forkJoin([
      this._requestService.getTypeRequest().pipe(take(1)),
      // this._requestService.getSubTypeRequest().pipe(take(1)),
      this._subTypesRequestService.getSubTypeRequestWithTypeRequestPlaceholder().pipe( take( 1 ) ),
      this._userCourseService.getCoursesOffer(filterUserCourse).pipe(take(1)),
    ]).subscribe({
      next: (data) => {
        console.log('getAllDataForUser',data);
        this.ALL_TYPE_REQUEST = data[0];
        this.ALL_SUBTYPE_REQUEST = data[1];
        this.ALL_USER_COURSES = data[2];
        this.panelRightIndex = 3;
        this._requestWatcherUserService.updateAllTypeRequestObservable(this.ALL_TYPE_REQUEST);
      },
      error: (err) => {
        console.log("ERROR getAllDataForUser",err)
      },
      complete: () => {
        console.log('complete getAllDataForUser');
      }
    })
  }

  requestUserCreated() {
    this.panelRightIndex = 0;
    this.userFound = false;
  }

  /** ****************************** New Code ****************************** */

  public showFloatingBox: boolean = false;
  public typeFilterToGetRequest: number = 0;
  private subscriptionShowFloatingBox: Subscription;
  private subscriptionLoadRequestToShow: Subscription;
  private subscriptionLoadSectionAdminToView: Subscription;

  ngOnDestroy() {
    this.subscriptionShowFloatingBox.unsubscribe();
    this.subscriptionLoadRequestToShow.unsubscribe();
    this.subscriptionLoadSectionAdminToView.unsubscribe();
  }

  closeUserFloatingBox(): void {
    this._requestWatcherUserService.closeFloatingBoxAdmin();
  }

  getRequestFilter(): string {
    let requestFilter = {
      order: 'updateDate DESC',
      where: {},
      limit: 1500, /** TODO: do pagination */
      include: [
        "requestSchool",
        "requestTypeRequest",
        "requestUserOwner",
        "requestStageReq",
        "requestStateReq",
        { requestCourseOffer: "course" },
        { requestHistories: "requestHistoryUser" },
        { 
          requestSubTypeRequest: [
            "subTypeReqStages",
            { reqMembersTemplate: "reqMemberTemplateStaff" }
          ]
        },
        {
          requestUserRequester: [
            "MilitarInfos",
            "ContactInfos",
            { UserDocuments: "TypeDocuments" },
            {
              parentsToStudents: [
                { 
                  parentUser: [
                    "ContactInfos",
                    { UserDocuments: "TypeDocuments" }
                  ]
                },
                {
                  attendantUser: [
                    "ContactInfos",
                    { UserDocuments: "TypeDocuments" }
                  ]
                }
              ]
            }
          ]
        },
        {
          relation: "filesReq",
          scope: {
            order: 'updateDate DESC',
            include: ["filesUserID"]
          }
        },
        {
          relation: "reqMembers",
          scope: {
            order: 'numSequence ASC',
            include: ["userapp", { reqMemberStaff: "userapp" }]
          }
        },
        {
          relation: "dispatchOrders",
          scope: {
            order: 'issueDate DESC'
          }
        }

      ]
    };
    if (this.typeFilterToGetRequest == 1) {
      requestFilter.where = {
        schoolID: { inq: [...this.ALL_PARENT_AND_CHILDRENS_SCHOOLS_IDS]}
      }
    } else {
      requestFilter.where = { or: [
        {userOwnerID: Number(this._authService.getCurrentUserID())},
        {userCurrentID: Number(this._authService.getCurrentUserID())}
      ]}
    }
    console.log('getRequestFilter', requestFilter);
    return JSON.stringify(requestFilter);
  }

}
